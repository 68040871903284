import { WebChatWidget } from './web-chat-widget'

export class WebChat {
  private baseUrl!: string | null
  private appKey!: string | null
  private buttonConfig!: { color?: string, icon?: string, size?: string, iconSize?: string, closeColor?: string } | null
  private customStyle!: string | null
  private previewMode: boolean = false
  private widget!: WebChatWidget | null

  withBaseUrl (baseUrl: string): WebChat {
    this.baseUrl = baseUrl
    return this
  }

  withAppKey (appKey: string): WebChat {
    this.appKey = appKey
    return this
  }

  withButton (buttonConfig: { color?: string, icon?: string, size?: string, iconSize?: string, closeColor?: string }): WebChat {
    this.buttonConfig = buttonConfig
    return this
  }

  withCustomStyle (style: string): WebChat {
    this.customStyle = style
    return this
  }

  withPreviewMode (): WebChat {
    this.previewMode = true
    return this
  }

  build (): void {
    this.widget = new WebChatWidget(
      this.baseUrl,
      this.appKey,
      this.buttonConfig,
      this.customStyle,
      this.previewMode
    )
  }

  destroy (): void {
    if (this.widget !== null) {
      this.widget.destroy()
    }
    this.widget = null
  }

  // sendMessage (content: string): void {
  //   this.widget.sendMessage(content)
  // }
}
